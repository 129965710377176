<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from './components/views/Home.vue';
import twemoji from 'twemoji';

export default {
  name: 'App',
  components: {
    Home
  },
  mounted() {
    twemoji.parse(document.body);
  },
}
</script>

<style lang="sass">
html
  height: 100%
  min-height: -webkit-fill-available
body
  padding: 0
  margin: 0
  height: 100%
  min-height: -webkit-fill-available
  overflow: hidden
#app
  height: 100%
  min-height: -webkit-fill-available
  img.emoji
    height: 1em
    width: 1em
    margin: 0 .05em 0 .1em
    vertical-align: -0.1em
  h3.with-emoji
    display: flex
    align-items: center
    img.emoji
      height: 2em
      width: 2em
      margin: 0 .5em 0 .05em
</style>
