<template>
    <section class="references">
        <div class="references-content">
            <div class="title">
                <h2>Références</h2>
                <p class="title-complement">ils me présentent mieux que moi</p>
            </div>
            <div class="articles-list">
                <div class="article" v-for="(article, index) in articles" :key="article.title" :style="{ backgroundImage: `url(${getImageUrl(article.image)})`}">
                    <div class="logo">
                        <div class="logo-container">
                            <img :src="getImageUrl(article.logo)" alt=""/>
                        </div>
                        <AnimatedWave :type="3" color="white" :delay="index"/>
                    </div>
                    <a class="content" :href="article.url" target="_blank">
                        <AnimatedWave :type="1" color="#6B00FF" :delay="index"/>
                        <div class="content-container">
                            <h3 class="article-title">{{ article.title }}</h3>
                            <p class="article-intro">{{ article.intro }}</p>
                            <p class="article-infos">
                                <span class="article-author">{{ article.author }}</span>
                                &nbsp;&middot;&nbsp;
                                <span class="article-date">{{ article.date }}</span>
                            </p>
                            <a :href="article.url" target="_blank" class="article-cta">
                                Découvrir {{ article.type }}
                                <img src="../assets/icons/cta-arrow.svg" :alt="`Découvrir ${article.type}`"/>
                            </a>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AnimatedWave from "./utils/AnimatedWave.vue"

export default {
    name: "references",
    components: { AnimatedWave },
    data() {
        return {
            articles: [
                {
                    title: "Meet the Developer Behind the Emoji Mashup Bot",
                    intro: "The Emoji Mashup Bot Is Creating Fun, Weird, and Useful New Emoji. Meet the 18-Year-Old Developer Behind It.",
                    image: "illustrations/time-28082019.webp",
                    type: "l'article",
                    date: "28 Août 2019",
                    url: "https://time.com/5663562/emoji-mashup-bot/",
                    author: "Time",
                    logo: "logos/time.svg",
                },
                {
                    title: "Why I hate emojis but love Twitter's Emoji Mashup Bot",
                    intro: "I had never quite felt seen by a round yellow cartoon until I met the angry, party face.",
                    image: "illustrations/wired-28072019.webp",
                    type: "l'article",
                    date: "28 Juillet 2019",
                    url: "https://www.wired.co.uk/article/emoji-mashup-bot-twitter",
                    author: "Wired UK",
                    logo: "logos/wired.svg",
                },
                {
                    title: "Qui est Louan Ben, le nouveau Français de 21 ans sacré king ultime de Twitter ?",
                    intro: "Son Emoji Mashup Bot est l’un des cinq comptes à être suivis par Twitter sur Twitter. On vous explique.",
                    image: "illustrations/konbini-17022022.jpg",
                    type: "l'article",
                    date: "17 Février 2022",
                    url: "https://techno.konbini.com/fr/societe/qui-est-louan-ben-le-nouveau-francais-de-21-ans-sacre-king-ultime-de-twitter/",
                    author: "Konbini Techno",
                    logo: "logos/konbini-techno.svg",
                },
                {
                    title: "JT 1945 - Jeu \"Wordle\" : lettre à part",
                    intro: "C'est le phénomène du moment. Le jeu Wordle passionne des millions de personnes.",
                    image: "illustrations/M6-15012022.png",
                    type: "la vidéo",
                    date: "15 Janvier 2022",
                    url: "https://www.facebook.com/M6info/videos/jeu-wordle-lettre-%C3%A0-part/669154264455951/",
                    author: "M6",
                    logo:"logos/m6.svg",
                },
                {
                    title: "Talking To The Guy Behind The Twitter Bot Making The Emoji You've Always Wanted",
                    intro: "All the feelings you didn't know you had",
                    image: "illustrations/digg-22072019.png",
                    type: "l'article",
                    date: "22 Juillet 2019",
                    url: "https://digg.com/2019/emoji-mashup-bot-louan-bengmah-interview-twitter",
                    author: "Digg",
                    logo: "logos/digg.svg",
                },
                {
                    title: "\"Wordle\", le Motus 2.0 existe aussi en français",
                    intro: "Wordle est devenu en quelques jours le jeu phénomène, qui consiste à trouver chaque jour un mot de 5 lettres en maximum 6 tentatives.",
                    image: "illustrations/bfmtv-17012022.png",
                    type: "la vidéo",
                    date: "17 Janvier 2022",
                    url: "https://www.bfmtv.com/replay-emissions/la-pepite/wordle-le-motus-2-0-existe-aussi-en-francais_VN-202201170076.html",
                    author: "BFMTV",
                    logo: "logos/bfmtv.svg",
                },
                {
                    title: "Le RéCAP #520 - L'invité",
                    intro: "Le Récap du Mercredi 9 Mars",
                    image: "illustrations/lestream-09032022.jpg",
                    type: "la vidéo",
                    date: "09 Mars 2022",
                    url: "https://youtu.be/egbwmPHj-1I?t=5246",
                    author: "LeStream",
                    logo: "logos/lestream.png",
                },
                {
                    title: "Emoji mash-up: This is what happens when one emoji just isn't enough",
                    intro: "An 18-year-old student from France has designed a bot which mashes two emojis together to create a new one - and the results are very funny!",
                    image: "illustrations/bbc-26072019.jpg",
                    type: "l'article",
                    date: "26 Juillet 2019",
                    url: "https://www.bbc.co.uk/newsround/49110372",
                    author: "BBC Newsround",
                    logo: "logos/bbc.svg",
                },
                {
                    title: "How A French Teenager Created One Of The Hottest Twitter Accounts Of Summer 2019",
                    intro: "A French programmer struck social media gold July 16 after he launched @EmojiMashupBot",
                    image: "illustrations/forbes-28072019.png",
                    type: "l'article",
                    date: "28 Juillet 2019",
                    url: "https://www.forbes.com/sites/fernandoalfonso/2019/07/28/how-a-french-teenager-created-one-of-the-hottest-twitter-accounts-of-summer-2019-emojimashupbot/?sh=49e377b23981",
                    author: "Forbes",
                    logo: "logos/forbes.svg",
                },
            ],
        };
    },
    methods: {
        getImageUrl(filename) {
            return require(`../assets/img/references/${filename}`);
        },
    }
}
</script>

<style lang="sass" scoped>
$background-color: #282B5F
$secondary-background-color: #06066B
$primary: #FB9C47

.references
    min-height: 100vh
    width: 100vw
    display: flex
    background: $secondary-background-color
    .references-content
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        box-sizing: border-box
        .title
            position: relative
            width: 494.4px
            h2
                color: $primary
                font-size: 6em
                display: inline
                position: relative
                z-index: 2
                &::before
                    content: "Références"
                    font-family: 'Outfit', sans-serif
                    color: transparent
                    font-size: 6rem
                    display: inline
                    -webkit-text-stroke: 0.2em $secondary-background-color
                    color: transparent
                    position: absolute
                    top: -5px
                    left: 0
                    z-index: -1
                    -webkit-text-decoration: white solid underline
                    text-decoration: white solid underline
                    text-decoration-thickness: 3.5px
                    text-underline-offset: 17px
                    width: 101%
            &-complement
                position: absolute
                background: $secondary-background-color
                padding: 0 0 0 10px
                bottom: 0
                right: 0
                z-index: 3
        .articles-list
            padding: 56px 48px 0 48px
            max-width: 1000px
            display: grid
            grid-template-columns: repeat(3, 1fr)
            .article
                background: #4800AC
                max-width: 100%
                width: 310px
                height: 420px
                background: white
                margin: 0 20px
                border-radius: 10px
                margin-bottom: 40px
                background-size: contain
                background-repeat: no-repeat
                background-position: top 10% center
                display: flex
                flex-direction: column
                align-items: center
                justify-content: space-between
                overflow: hidden
                box-shadow: 0px 5px 0px 0px #4800AC
                .logo
                    position: relative
                    width: 100%
                    .logo-container
                        background: white
                        height: 35px
                        width: 100%
                        position: relative
                        z-index: 2
                        padding-top: 10px
                        //padding-bottom: 5px
                        box-sizing: border-box
                        display: flex
                        justify-content: center
                    .animated-wave
                        width: 100%
                        position: absolute
                        z-index: 1
                        top: 0
                        left: 0
                .content
                    position: relative
                    width: 100%
                    height: 54%
                    text-decoration: none
                    .content-container
                        background: #6B00FF
                        height: 100%
                        width: 100%
                        position: relative
                        z-index: 2
                        padding: 14px
                        padding-top: 0
                        box-sizing: border-box
                        display: flex
                        flex-direction: column
                        h3
                            margin-bottom: 0.7rem
                        p
                            font-size: 0.9em
                            &.article-intro
                                margin-bottom: 0.7rem
                            &.article-infos
                                font-size: 0.8em
                            .article-author
                                font-weight: bold
                                text-transform: uppercase
                            .article-date
                                font-weight: 300
                        .article-cta
                            margin-top: auto
                            align-self: flex-end
                            text-decoration: none
                            font-size: 0.9em
                            img
                                height: 0.5em

                    .animated-wave
                        width: 100%
                        position: absolute
                        z-index: 1
                        top: -35%
                        left: 0
    @media (max-width: 768px)
        .references-content
            padding: 0 24px
            box-sizing: border-box
            .title
                width: 100%
                h2
                    width: 100%
                    text-align: center
                    font-size: 3rem
                    display: block
                    &::before
                        font-size: 3rem
                        -webkit-text-stroke: 0.3em $secondary-background-color
                        text-underline-offset: 10px
                &-complement
                    display: none
                    font-size: 1rem
                    bottom: -0.5rem
                    line-height: 0.5rem
            .articles-list
                grid-template-columns: repeat(1, 1fr)
                padding: 0
                padding-top: 3em
                width: 100%
                .article
                    margin: 0
                    margin-bottom: 2em
                    width: 100%
                    height: 420px
                    .content
                        .content-container
</style>