<template>
    <div class="animated-wave">
        <svg :class="{ shadow: hasShadow }" width="100%" height="100%" id="svg" viewBox="0 0 1440 500" xmlns="http://www.w3.org/2000/svg">
            <path v-if="type === 1" :style="{ animationDelay: `-${delay * 3}s`}" d="M 0,500 C 0,500 0,250 0,250 C 91.89285714285714,255.39285714285714 183.78571428571428,260.7857142857143 318,273 C 452.2142857142857,285.2142857142857 628.7499999999999,304.25 752,300 C 875.2500000000001,295.75 945.2142857142858,268.2142857142857 1051,256 C 1156.7857142857142,243.78571428571428 1298.392857142857,246.89285714285714 1440,250 C 1440,250 1440,500 1440,500 Z" stroke="none" stroke-width="0" :fill="color" class="path-1"></path>
            <path v-else-if="type === 2" :style="{ animationDelay: `-${delay * 3}s`}" d="M 0,400 C 0,400 0,200 0,200 C 78.12504294057024,164.4177258673995 156.25008588114048,128.83545173479902 231,149 C 305.7499141188595,169.16454826520098 377.1246994160082,245.0759189282034 439,262 C 500.8753005839918,278.9240810717966 553.2511164548266,236.86087255238746 619,233 C 684.7488835451734,229.13912744761254 763.8708347646857,263.4805908622467 832,242 C 900.1291652353143,220.5194091377533 957.2655444864308,143.21676399862588 1030,125 C 1102.7344555135692,106.7832360013741 1191.066987289591,147.65235314324974 1262,170 C 1332.933012710409,192.34764685675026 1386.4665063552045,196.17382342837513 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" stroke-width="0" :fill="color" class="transition-all duration-300 ease-in-out delay-150 path-2"></path>
            <path v-else-if="type === 3" :style="{ animationDelay: `-${delay * 3}s`}" d="M 0,500 C 0,500 0,250 0,250 C 119.64285714285714,230.53571428571428 239.28571428571428,211.07142857142858 343,195 C 446.7142857142857,178.92857142857142 534.5000000000001,166.25 666,163 C 797.4999999999999,159.75 972.7142857142856,165.92857142857144 1109,182 C 1245.2857142857144,198.07142857142856 1342.6428571428573,224.03571428571428 1440,250 C 1440,250 1440,500 1440,500 Z" stroke="none" stroke-width="0" :fill="color" class="path-3" transform="rotate(-180 720 250)"></path>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'animated-wave',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        delay: {
            type: Number,
            default: 0,
        },
        type: {
            type: Number,
            default: 1,
        },
        hasShadow: {
            type: Boolean,
            default: true,
        }
    },
}
</script>

<style lang="sass" scoped>
svg
    .path-1
        animation: pathAnim-1 20s infinite linear
    .path-2
        animation: pathAnim-2 20s infinite linear
    .path-3
        animation: pathAnim-3 20s infinite linear
    &.shadow
        filter: drop-shadow(0 -3px 30px rgba(0, 0, 0, 0.5))
@keyframes pathAnim-1
    0%
        d: path("M 0,500 C 0,500 0,250 0,250 C 91.89285714285714,255.39285714285714 183.78571428571428,260.7857142857143 318,273 C 452.2142857142857,285.2142857142857 628.7499999999999,304.25 752,300 C 875.2500000000001,295.75 945.2142857142858,268.2142857142857 1051,256 C 1156.7857142857142,243.78571428571428 1298.392857142857,246.89285714285714 1440,250 C 1440,250 1440,500 1440,500 Z")

    25%
        d: path("M 0,500 C 0,500 0,250 0,250 C 138.92857142857144,244.71428571428572 277.8571428571429,239.42857142857144 399,264 C 520.1428571428571,288.57142857142856 623.5000000000001,342.99999999999994 719,325 C 814.4999999999999,307.00000000000006 902.1428571428571,216.57142857142858 1021,192 C 1139.857142857143,167.42857142857142 1289.9285714285716,208.71428571428572 1440,250 C 1440,250 1440,500 1440,500 Z")

    50%
        d: path("M 0,500 C 0,500 0,250 0,250 C 116.32142857142858,219.17857142857144 232.64285714285717,188.35714285714286 360,202 C 487.35714285714283,215.64285714285714 625.7499999999999,273.75 751,306 C 876.2500000000001,338.25 988.3571428571429,344.6428571428571 1101,331 C 1213.642857142857,317.3571428571429 1326.8214285714284,283.67857142857144 1440,250 C 1440,250 1440,500 1440,500 Z")

    75%
        d: path("M 0,500 C 0,500 0,250 0,250 C 115.78571428571428,223.67857142857144 231.57142857142856,197.35714285714286 357,200 C 482.42857142857144,202.64285714285714 617.4999999999999,234.25 746,249 C 874.5000000000001,263.75 996.4285714285716,261.6428571428571 1111,259 C 1225.5714285714284,256.3571428571429 1332.7857142857142,253.17857142857144 1440,250 C 1440,250 1440,500 1440,500 Z")

    100%
        d: path("M 0,500 C 0,500 0,250 0,250 C 91.89285714285714,255.39285714285714 183.78571428571428,260.7857142857143 318,273 C 452.2142857142857,285.2142857142857 628.7499999999999,304.25 752,300 C 875.2500000000001,295.75 945.2142857142858,268.2142857142857 1051,256 C 1156.7857142857142,243.78571428571428 1298.392857142857,246.89285714285714 1440,250 C 1440,250 1440,500 1440,500 Z")

@keyframes pathAnim-2
    0%
        d: path("M 0,400 C 0,400 0,200 0,200 C 78.12504294057024,164.4177258673995 156.25008588114048,128.83545173479902 231,149 C 305.7499141188595,169.16454826520098 377.1246994160082,245.0759189282034 439,262 C 500.8753005839918,278.9240810717966 553.2511164548266,236.86087255238746 619,233 C 684.7488835451734,229.13912744761254 763.8708347646857,263.4805908622467 832,242 C 900.1291652353143,220.5194091377533 957.2655444864308,143.21676399862588 1030,125 C 1102.7344555135692,106.7832360013741 1191.066987289591,147.65235314324974 1262,170 C 1332.933012710409,192.34764685675026 1386.4665063552045,196.17382342837513 1440,200 C 1440,200 1440,400 1440,400 Z")

    25%
        d: path("M 0,400 C 0,400 0,200 0,200 C 50.537959464101704,203.6853315012023 101.07591892820341,207.37066300240465 175,186 C 248.9240810717966,164.62933699759535 346.23428375128816,118.20267949158364 428,138 C 509.76571624871184,157.79732050841636 575.9869460666439,243.8186190312607 631,273 C 686.0130539333561,302.1813809687393 729.8179319821367,274.5228443833734 795,248 C 860.1820680178633,221.4771556166266 946.7413260048095,196.0900034352456 1028,192 C 1109.2586739951905,187.9099965647544 1185.2167639986258,205.1171418756441 1253,210 C 1320.7832360013742,214.8828581243559 1380.3916180006872,207.44142906217795 1440,200 C 1440,200 1440,400 1440,400 Z")

    50%
        d: path("M 0,400 C 0,400 0,200 0,200 C 56.02404671934045,228.13260048093437 112.0480934386809,256.26520096186874 185,262 C 257.9519065613191,267.73479903813126 347.831672964617,251.07179663345931 422,241 C 496.168327035383,230.92820336654069 554.6252147028513,227.44761250429403 620,219 C 685.3747852971487,210.55238749570597 757.6674682239778,197.13775334936452 826,205 C 894.3325317760222,212.86224665063548 958.7049124012369,242.00137409824802 1020,235 C 1081.295087598763,227.99862590175198 1139.5128821710753,184.85675025764343 1209,173 C 1278.4871178289247,161.14324974235657 1359.2435589144625,180.5716248711783 1440,200 C 1440,200 1440,400 1440,400 Z")

    75%
        d: path("M 0,400 C 0,400 0,200 0,200 C 83.20439711439369,177.03675712813467 166.40879422878737,154.07351425626933 243,169 C 319.5912057712126,183.92648574373067 389.5692201992443,236.74270010305736 440,224 C 490.4307798007557,211.25729989694264 521.3143249742358,132.9556853315012 594,133 C 666.6856750257642,133.0443146684988 781.1734799038132,211.43455857093784 849,248 C 916.8265200961868,284.56544142906216 937.9917554105118,279.30608038474753 997,249 C 1056.0082445894882,218.69391961525247 1152.8594984541394,163.34111989007212 1233,151 C 1313.1405015458606,138.65888010992788 1376.5702507729302,169.32944005496392 1440,200 C 1440,200 1440,400 1440,400 Z")

    100%
        d: path("M 0,400 C 0,400 0,200 0,200 C 78.12504294057024,164.4177258673995 156.25008588114048,128.83545173479902 231,149 C 305.7499141188595,169.16454826520098 377.1246994160082,245.0759189282034 439,262 C 500.8753005839918,278.9240810717966 553.2511164548266,236.86087255238746 619,233 C 684.7488835451734,229.13912744761254 763.8708347646857,263.4805908622467 832,242 C 900.1291652353143,220.5194091377533 957.2655444864308,143.21676399862588 1030,125 C 1102.7344555135692,106.7832360013741 1191.066987289591,147.65235314324974 1262,170 C 1332.933012710409,192.34764685675026 1386.4665063552045,196.17382342837513 1440,200 C 1440,200 1440,400 1440,400 Z")

@keyframes pathAnim-3
    0%
        d: path("M 0,500 C 0,500 0,250 0,250 C 119.64285714285714,230.53571428571428 239.28571428571428,211.07142857142858 343,195 C 446.7142857142857,178.92857142857142 534.5000000000001,166.25 666,163 C 797.4999999999999,159.75 972.7142857142856,165.92857142857144 1109,182 C 1245.2857142857144,198.07142857142856 1342.6428571428573,224.03571428571428 1440,250 C 1440,250 1440,500 1440,500 Z")

    25%
        d: path("M 0,500 C 0,500 0,250 0,250 C 148.14285714285717,267.3571428571429 296.28571428571433,284.7142857142857 415,287 C 533.7142857142857,289.2857142857143 622.9999999999999,276.50000000000006 742,251 C 861.0000000000001,225.49999999999994 1009.7142857142858,187.28571428571428 1131,185 C 1252.2857142857142,182.71428571428572 1346.142857142857,216.35714285714286 1440,250 C 1440,250 1440,500 1440,500 Z")

    50%
        d: path("M 0,500 C 0,500 0,250 0,250 C 123.46428571428572,279.67857142857144 246.92857142857144,309.35714285714283 358,309 C 469.07142857142856,308.64285714285717 567.7500000000001,278.25 687,257 C 806.2499999999999,235.74999999999997 946.0714285714284,223.64285714285717 1075,224 C 1203.9285714285716,224.35714285714283 1321.9642857142858,237.17857142857142 1440,250 C 1440,250 1440,500 1440,500 Z")

    75%
        d: path("M 0,500 C 0,500 0,250 0,250 C 87.32142857142861,267.7142857142857 174.64285714285722,285.42857142857144 297,259 C 419.3571428571428,232.57142857142858 576.7499999999998,162 710,171 C 843.2500000000002,180 952.3571428571429,268.57142857142856 1070,295 C 1187.642857142857,321.42857142857144 1313.8214285714284,285.7142857142857 1440,250 C 1440,250 1440,500 1440,500 Z")

    100%
        d: path("M 0,500 C 0,500 0,250 0,250 C 119.64285714285714,230.53571428571428 239.28571428571428,211.07142857142858 343,195 C 446.7142857142857,178.92857142857142 534.5000000000001,166.25 666,163 C 797.4999999999999,159.75 972.7142857142856,165.92857142857144 1109,182 C 1245.2857142857144,198.07142857142856 1342.6428571428573,224.03571428571428 1440,250 C 1440,250 1440,500 1440,500 Z")

</style>
